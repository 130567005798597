* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.large-font {
  font-family: 'Helvetica', sans-serif;
  font-size: 4em;
}

.medium-font {
  font-family: 'nunito', sans-serif;
  font-size: 2em;
}
