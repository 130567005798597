.nav-bar {
  background-color: #222;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  font-size: 1.2rem;
}

.logo {
  color: #fff;
  font-size: 2rem;
  font-weight: 300;
}

.logo a {
  text-decoration: none;
  color: #fff;
}

.nav-items {
  display: flex;
  list-style: none;
}

.nav-button {
  border: none;
  background-color: blueviolet;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 5px;
  color: #fff;
  font-family: inherit;
  font-size: 1rem;
  font-weight:500;
}
