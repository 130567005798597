.service-card-container {
  border: 1px solid #c1c0c0;
  width: 550px;
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

.service-card-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 30px;
  background-color: #c1c0c0;
}

.service-card-title {
  font-size: 25px;
  font-weight: lighter;
  margin-bottom: 20px;
}

.service-card-description {
  font-size: 16px;
  margin-bottom: 20px;
}
.service-card-btn {
  background-color: #f1f1f1;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
}
